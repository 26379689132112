import React from 'react';
import { PrivateRoute } from './privateRoute';

const FaturamentoCliente = React.lazy(() => import('../../../pages/cliente/faturamento/FaturamentoCliente'));
const FaturaCliente = React.lazy(() => import('../../../pages/cliente/faturamento/FaturaCliente'));
const CalcularFaturamento = React.lazy(() =>
    import('../../../pages/backoffice/faturamento/calcularfaturamento/CalcularFaturamento')
);
const CalcularFaturamentoFixo = React.lazy(() =>
    import('../../../pages/backoffice/faturamento/calcularfaturamento/CalcularFaturamentoFixo')
);
const FaturaList = React.lazy(() => import('../../../pages/backoffice/faturamento/fatura/FaturaList'));
const FaturaCadastro = React.lazy(() => import('../../../pages/backoffice/faturamento/fatura/FaturaCadastro'));
const ContratoForm = React.lazy(() => import('../../../pages/contrato/ContratoForm'));
const ContratoList = React.lazy(() => import('../../../pages/contrato/ContratoList'));
const Disponibilidade = React.lazy(() => import('../../../pages/Disponibilidade/Disponibilidade'));
const AgrupamentoList = React.lazy(() => import('../../../pages/backoffice/faturamento/fatura/AgrupamentoList'));

export const faturamentoRoute = [
    {
        path: '/faturamento/fatura',
        exact: true,
        roles: ['master', 'gestorcliente', 'gestorcentral', 'faturista'],
        component: AgrupamentoList,
        route: PrivateRoute,
    },
    // {
    //     path: '/faturamento/faturas/cliente',
    //     exact: true,
    //     roles: ['master', 'gestorcliente'],
    //     component: FaturamentoCliente,
    //     route: PrivateRoute
    // },
    // {
    //     path: '/faturamento/fatura/cliente/:id',
    //     exact: true,
    //     roles: ['master', 'gestorcliente'],
    //     component: FaturaCliente,
    //     route: PrivateRoute
    // },
    {
        path: '/faturamento/calcularfaturamento',
        exact: true,
        roles: ['master', 'gestorcentral', 'faturista'],
        component: CalcularFaturamento,
        route: PrivateRoute,
    },
    {
        path: '/faturamento/calcularfaturamentofixo',
        exact: true,
        roles: ['master', 'gestorcentral', 'faturista'],
        component: CalcularFaturamentoFixo,
        route: PrivateRoute,
    },
    {
        path: '/faturamento/fatura/:agrupamentoId',
        exact: true,
        roles: ['master', 'gestorcentral', 'faturista', 'gestorcliente'],
        component: FaturaList,
        route: PrivateRoute,
    },
    // {
    //     path: '/faturamento/fatura/adicionar',
    //     exact: true,
    //     roles: ['master', 'gestorcentral', 'faturista'],
    //     component: FaturaCadastro,
    //     route: PrivateRoute
    // },
    {
        path: '/faturamento/fatura/visualizar/:id',
        exact: true,
        roles: ['master', 'gestorcentral', 'faturista', 'gestorcliente'],
        component: FaturaCadastro,
        route: PrivateRoute,
    },
    // {
    //     path: '/faturamento/fatura/deletar/:id',
    //     exact: true,
    //     roles: ['master', 'gestorcentral', 'faturista'],
    //     component: FaturaCadastro,
    //     route: PrivateRoute
    // },
    {
        path: '/contrato',
        exact: true,
        roles: ['master', 'gestorcentral', 'faturista'],
        component: ContratoList,
        route: PrivateRoute,
    },
    {
        path: '/contrato/adicionar',
        exact: true,
        roles: ['master', 'gestorcentral', 'faturista'],
        component: ContratoForm,
        route: PrivateRoute,
    },
    {
        path: '/contrato/atualizar/:id',
        exact: true,
        roles: ['master', 'gestorcentral', 'faturista'],
        component: ContratoForm,
        route: PrivateRoute,
    },
    {
        path: '/contrato/deletar/:id',
        exact: true,
        roles: ['master', 'gestorcentral', 'faturista'],
        component: ContratoForm,
        route: PrivateRoute,
    },
    {
        path: '/disponibilidade',
        exact: true,
        roles: ['master', 'gestorcentral', 'faturista', 'auditor'],
        component: Disponibilidade,
        route: PrivateRoute,
    },
];
