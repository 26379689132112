import { URL_PORTAL_V2 } from '../../../helpers/url';

const gestorMenu = [
    {
        header: 'Painel Administrativo',
        path: '/admin',
        name: 'Cadastros',
        icon: 'dripicons-document',
        children: [
            {
                name: 'Motoristas',
                path: '/motorista',
            },
            {
                name: 'Veículos',
                path: '/veiculo',
            },
            {
                name: 'Passageiros',
                path: '/passageiro',
            },
            {
                name: 'Locais',
                path: '/local',
            },
            {
                name: 'Centro de custos',
                path: '/centrocusto',
            },
            {
                name: 'Centro de custos interno',
                path: '/centrocustointerno',
            },
            {
                name: 'Contratos',
                path: '/contrato',
            },
            {
                name: 'Turnos',
                path: '/turno',
            },
            {
                name: 'Grupo de Veículos',
                path: '/grupoveiculo',
            },
            {
                name: 'Tipos de Operação',
                path: '/tipooperacao',
            },
            {
                name: 'Feriados',
                path: '/feriado',
            },
            {
                name: 'Nova Solicitação de Corrida',
                path: '/corrida/newsolicitacao/adicionar',
            },
            {
                name: 'Alteração Enderenço',
                path: '/alterarendpass',
            },
            {
                name: 'Notification-alert-',
                path: `${URL_PORTAL_V2}/control-panel/_registrations/notification-alert`,
            },
            {
                name: 'Category',
                path: `${URL_PORTAL_V2}/control-panel/_registrations/category`,
            },
            {
                name: 'Notification-alert',
                path: `${URL_PORTAL_V2}/control-panel/_registrations/notification-alert`,
            },
            {
                name: 'Cancellation-reason',
                path: `${URL_PORTAL_V2}/work-order-panel/_registrations/cancellation-reason`,
            },
            {
                name: 'Rejection-reason',
                path: `${URL_PORTAL_V2}/work-order-panel/_registrations/rejection-reason`,
            },
            {
                name: 'Evaluation-reasons',
                path: `${URL_PORTAL_V2}/work-order-panel/_registrations/evaluation-reasons`,
            },
            {
                name: 'Region',
                path: `${URL_PORTAL_V2}/control-panel/_services/region`,
            },
            {
                name: 'SLA',
                path: `${URL_PORTAL_V2}/contracts-panel/_registrations/sla`,
            },
            {
                name: 'Rate-Identity',
                path: `${URL_PORTAL_V2}/financial-panel/_registrations/rate`,
            },
        ],
    },
    {
        header: 'Painel Faturamento',
        path: '/faturamento',
        name: 'Faturamento',
        icon: 'uil-calculator-alt',
        children: [
            {
                name: 'Calcular Faturamento',
                path: '/calcularfaturamento',
                children: [
                    {
                        name: 'Rede',
                        path: '/faturamento/calcularfaturamento',
                    },
                    {
                        name: 'Fixo',
                        path: '/faturamento/calcularfaturamentofixo',
                    },
                ],
            },
            {
                name: 'Faturas',
                path: '/faturamento/fatura',
            },
            {
                name: 'Disponibilidade',
                path: '/disponibilidade',
            },
        ],
    },
    {
        header: 'Painel Operacional',
        path: '/operacional',
        name: 'Corridas',
        icon: 'mdi mdi-car-connected',
        children: [
            {
                name: 'Acompanhamento',
                path: '/corrida/acompanhamento',
            },
            {
                name: 'Histórico',
                path: '/corrida/historico',
            },
            {
                name: 'Monitoramento',
                children: [
                    { name: 'Horas Online', path: '/monitoramento/horas' },
                    { name: 'Corridas', path: '/monitoramento/corridas' },
                    { name: 'Completo', path: '/monitoramento/full' },
                ],
            },
        ],
    },
    {
        path: '/aprovacao',
        name: 'Aprovações',
        icon: 'uil-check-square',
        children: [
            {
                name: 'Endereço passageiro',
                path: '/alterarendpass',
            },

            {
                name: 'Solicitações motorista',
                path: '/aprovacao',
            },
        ],
    },
    {
        path: '/auditoria',
        name: 'Auditoria',
        icon: ' mdi mdi-file-document-box-check-outline',
        children: [
            {
                name: 'Não Conformidades',
                path: '/nao-conformidade',
            },
            {
                name: 'Documentos vencidos',
                path: '/motorista/documentos',
            },
            {
                name: 'Motoristas pendentes',
                path: '/motorista/aprovacoes',
            },
        ],
    },
    {
        header: 'Painel Financeiro',
        path: '/financeiro',
        name: 'Financeiro',
        icon: 'mdi mdi-cash-usd-outline',
        children: [
            {
                name: 'Despesas',
                path: '/despesaaprovada',
            },
            {
                name: 'Tipo Despesas',
                path: '/tipodespesa',
            },
            {
                name: 'Tarifas',
                path: '/tarifa',
            },
            {
                name: 'Revisão de Reembolso',
                path: `${URL_PORTAL_V2}/drivers-panel/_services/refund-request`,
            },
        ],
    },
];

export const GestorMenu = () => gestorMenu;
