import { fetchJSON } from './api';
import { getLoggedInUser } from './authUtils';

import api from '../services/api';
import config from '../config';
import { URL_BASE } from './url';
import { authProtectedRoutes } from '../routes';

export const extrairEnderecoDeBusca = (obj) => {
    const { address_components, geometry, formatted_address } = obj;

    if (!geometry) {
        return;
    }

    if (address_components) {
        const endereco = {
            logradouro: '',
            numero: '',
            bairro: '',
            cidade: '',
            estado: '',
            sigla: '',
            cep: '',
            latitude: 0,
            longitude: 0,
            enderecoFormatado: '',
        };

        for (let index = 0; index < address_components.length; index++) {
            const element = address_components[index];

            switch (element.types[0]) {
                case 'street_number':
                    endereco.numero = element.long_name;
                    break;
                case 'route':
                    endereco.logradouro = element.long_name;
                    break;
                case 'sublocality_level_1':
                    endereco.bairro = element.long_name;
                    break;
                case 'administrative_area_level_4':
                    endereco.bairro = element.long_name;
                    break;
                case 'administrative_area_level_2':
                    endereco.cidade = element.long_name;
                    break;
                case 'administrative_area_level_1':
                    endereco.estado = element.long_name;
                    endereco.sigla = element.short_name;
                    break;
                case 'postal_code':
                    endereco.cep = element.long_name;
                    break;
                default:
                    break;
            }
        }

        endereco.latitude = geometry.location.lat(); // Busca a latitude
        endereco.longitude = geometry.location.lng(); // busca a longitude
        endereco.enderecoFormatado = formatted_address;

        return endereco;
    }

    return {};
};

export const getEnderecoCompleto = (endereco) => {
    if (!endereco) return '';

    var enderecoCompleto = '';

    if (endereco.logradouro) enderecoCompleto = endereco?.logradouro;
    if (endereco.numero)
        enderecoCompleto = enderecoCompleto + (enderecoCompleto?.length > 0 ? ', ' : '') + endereco?.numero;
    if (endereco.bairro)
        enderecoCompleto = enderecoCompleto + (enderecoCompleto?.length > 0 ? ' - ' : '') + endereco?.bairro;
    if (endereco.cidade)
        enderecoCompleto = enderecoCompleto + (enderecoCompleto?.length > 0 ? ', ' : '') + endereco?.cidade;
    if (endereco.sigla)
        enderecoCompleto = enderecoCompleto + (enderecoCompleto?.length > 0 ? ' - ' : '') + endereco?.sigla;

    return enderecoCompleto;
};

export const formatStringTime = (minutes) => {
    const data = new Date('2020-01-01 00:00:00');
    data.setMinutes(minutes ?? 0);

    const hora = data.getHours();
    const minuto = data.getMinutes();

    const time = `${hora < 10 ? '0' + hora : hora}:${minuto < 10 ? '0' + minuto : minuto}:00`;

    return time;
};

export const formatCurrency = (value) => {
    return Number(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
};

export const formatNumber = (value) => {
    return Number(value).toLocaleString('pt-br');
};

export const getFileData = (file, callback) => {
    if (file) {
        if (Math.round(file.size / 1000) <= 10240) {
            // Novo FileReader
            let reader = new FileReader();

            // Converto o arquivo para base64
            reader.readAsDataURL(file);

            // carrego o arquivo ...
            reader.onload = () => {
                // Crio um objeto fileInfo com info do arquivo
                let fileInfo = {
                    name: file.name,
                    ext: file.name.split('.').pop(),
                    type: file.type,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file: file,
                    url: URL.createObjectURL(file),
                };

                callback(fileInfo);
            };
        }
    } else {
        callback(undefined);
    }
};

export const getPosicaoMotorista = async (codigoIntegracao) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getLoggedInUser()?.token,
        },
    };

    var result = await fetchJSON('/central/v1/api/v1/taximachine/posicaomotorista/' + codigoIntegracao, options);
    return result;
};

export const getDistanceAndTimeGoogleApi = async ({ origin, destination }) => {
    const { data } = await api.get(
        `/central/v1/api/v1/google/calcular/distanciatempo?origens=${origin.latitude},${origin.longitude}&destinos=${destination.latitude},${destination.longitude}`
    );

    return {
        distance: data.rows[0].elements[0].distance.text,
        duration: data.rows[0].elements[0].duration.text,
    };
};

export const getDistanceAndTimeRoutesApi = async ({ origin, destination }) => {
    const { data } = await api.post(`https://routeautonomoz.azurewebsites.net/rides`, {
        waypoints: [
            {
                origin: {
                    latitude: origin.latitude,
                    longitude: origin.longitude,
                },
                destination: {
                    latitude: destination.latitude,
                    longitude: destination.longitude,
                },
            },
        ],
    });

    return {
        distance: data.waypoints.distance,
        duration: data.waypoints.estimated_time,
    };
};

export const getDistanceAndTimeOSRMApi = async ({ origin, destination }) => {

    const api_url = `${URL_BASE}/osrm/route/v1/driving`;

    const options = {
        method: 'GET',
    };

    const response = await fetchJSON(
        `${api_url}/${origin.longitude},${origin.latitude};${destination.longitude},${destination.latitude}`,
        options
    );

    const { code, routes } = response;

    if (code === 'Ok') {
        const { distance, duration } = routes[0];

        return {
            distance: `${(distance / 1000).toFixed(1)} km`,
            duration: new Date(duration * 1000).toISOString().substr(11, 8),
        };
    } else {
        return {
            distance: '',
            duration: '',
        };
    }
};

export const getSolicitacaoCorridaNovaParadas = async (solicitacaoCorridaId) => {
    const { data } = await api.get(`/central/v1/api/v1/solicitacaoCorridaNova/paradas/${solicitacaoCorridaId}`);
    return data;
};

export const loadScript = (url) => {
    let script = document.createElement('script');
    script.type = 'text/javascript';

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = null;
            }
        };
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
};

// export const getGoogleDistanceAndTime = (latO, lngO, latD, lngD) => {

//     return new Promise((resolve) => {

//         var origin1 = new window.google.maps.LatLng(latO, lngO);
//         var origin2 = new window.google.maps.LatLng(latD, lngD);

//         var service = new window.google.maps.DistanceMatrixService();
//         service.getDistanceMatrix(
//             {
//                 origins: [origin1],
//                 destinations: [origin2],
//                 travelMode: 'DRIVING'
//             }, callback);

//         function callback(response, status) {
//             if (status === "OK") {

//                 resolve({
//                     distance: response.rows[0].elements[0].distance.text,
//                     duration: response.rows[0].elements[0].duration.text
//                 })
//             }
//         }
//     })

// }

export const removeCaracterEspecial = (str, removeEnter) => {
    if (str) {
        var line = '';
        var lines = str.split('\n'); //multiLines contains your text
        for (var i = 0; i < lines.length; i++) {
            if (lines[i].startsWith(' ')) {
                line += ' ' + lines[i].trim();
            } else {
                line += ',' + lines[i].trim();
            }
        }

        line = line.substring(1).trim();

        var newStr = line
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/\s{2,}/g, ' ')
            .replace(',', '')
            .replace('-', '')
            .replace('!', '')
            .replace('@', '')
            .replace('_', '')
            .replace(';', '')
            .replace(':', '')
            .replace('.', '')
            .replace('/', '')
            .trim();

        if (removeEnter) newStr = newStr.replace(/\r?\n|\r/, ' ');

        return newStr;
    }

    return '';
};

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};

export const allRoutesPath = (listProps) => {
    const list = listProps ?? authProtectedRoutes();

    let paths = [];

    for (let i = 0; i < list.length; i++) {
        const element = list[i];
        if (element.children) {
            const subItems = allRoutesPath(element.children);
            if (Array.isArray(subItems)) {
                paths = paths.concat(subItems);
            } else {
                paths.push(subItems);
            }
        } else {
            paths.push(element.path);
        }
    }

    return paths;
};
