export const FATURAMENTO_CALCULAR = 'FATURAMENTO_CALCULAR';
export const FATURAMENTO_CALCULAR_SUCCESS = 'FATURAMENTO_CALCULAR_SUCCESS';
export const FATURAMENTO_CALCULAR_FAILED = 'FATURAMENTO_CALCULAR_FAILED';

export const FATURA_LIST           = 'FATURA_LIST';
export const FATURA_LIST_SUCCESS   = 'FATURA_LIST_SUCCESS';
export const FATURA_LIST_FAILED    = 'FATURA_LIST_FAILED';
export const FATURA_ADD            = 'FATURA_ADD';
export const FATURA_ADD_SUCCESS    = 'FATURA_ADD_SUCCESS';
export const FATURA_ADD_FAILED     = 'FATURA_ADD_FAILED';
export const FATURA_UPDATE         = 'FATURA_UPDATE';
export const FATURA_UPDATE_SUCCESS = 'FATURA_UPDATE_SUCCESS';
export const FATURA_UPDATE_FAILED  = 'FATURA_UPDATE_FAILED';
export const FATURA_REMOVE         = 'FATURA_REMOVE';
export const FATURA_REMOVE_SUCCESS = 'FATURA_REMOVE_SUCCESS';
export const FATURA_REMOVE_FAILED  = 'FATURA_REMOVE_FAILED';
export const FATURA_LOAD           = 'FATURA_LOAD'
export const FATURA_LOAD_SUCCESS   = 'FATURA_LOAD_SUCCESS';
export const FATURA_LOAD_FAILED    = 'FATURA_LOAD_FAILED';

export const ITEM_FATURA_LOAD   = 'ITEM_FATURA_LOAD';
export const ITEM_FATURA_LOAD_SUCCESS = 'ITEM_FATURA_LOAD_SUCCESS'
export const ITEM_FATURA_LOAD_FAILED = 'ITEM_FATURA_LOAD_FAILED'

export const ITEM_FATURA_NEW    = 'ITEM_FATURA_NEW';

export const ITEM_FATURA_ADD    = 'ITEM_FATURA_ADD';
export const ITEM_FATURA_ADD_SUCCESS = 'ITEM_FATURA_ADD_SUCCESS';
export const ITEM_FATURA_ADD_FAILED = 'ITEM_FATURA_ADD_FAILED';

export const ITEM_FATURA_UPDATE = 'ITEM_FATURA_UPDATE';
export const ITEM_FATURA_UPDATE_SUCCESS = 'ITEM_FATURA_UPDATE_SUCCESS';
export const ITEM_FATURA_UPDATE_FAILED  = 'ITEM_FATURA_UPDATE_FAILED';

export const ITEM_FATURA_REMOVE = 'ITEM_FATURA_REMOVE';
export const ITEM_FATURA_REMOVE_SUCCESS = 'ITEM_FATURA_REMOVE_SUCCESS';
export const ITEM_FATURA_REMOVE_FAILED  = 'ITEM_FATURA_REMOVE_FAILED';
export const ITEM_FATURA_REMOVE_START   = 'ITEM_FATURA_REMOVE_START';

export const ITEM_FATURA_CANCEL = 'ITEM_FATURA_CANCEL';

export const ITEM_FATURA_LIST = 'ITEM_FATURA_LIST';
export const ITEM_FATURA_LIST_SUCCESS = 'ITEM_FATURA_LIST_SUCCESS';
export const ITEM_FATURA_LIST_FAILED = 'ITEM_FATURA_LIST_FAILED';

export const ITEM_FATURA_LIST_ALL = 'ITEM_FATURA_LIST_ALL';
export const ITEM_FATURA_LIST_ALL_SUCCESS = 'ITEM_FATURA_LIST_ALL_SUCCESS';
export const ITEM_FATURA_LIST_ALL_FAILED = 'ITEM_FATURA_LIST_ALL_FAILED';

export const FATURA_PUBLICAR = 'FATURA_PUBLICAR'
export const FATURA_PUBLICAR_SUCCESS = 'FATURA_PUBLICAR_SUCCESS'
export const FATURA_PUBLICAR_FAILED = 'FATURA_PUBLICAR_FAILED'

export const FATURA_APROVAR = 'FATURA_APROVAR'
export const FATURA_APROVAR_SUCCESS = 'FATURA_APROVAR_SUCCESS'
export const FATURA_APROVAR_FAILED = 'FATURA_APROVAR_FAILED'

export const FATURA_REJEITAR = 'FATURA_REJEITAR'
export const FATURA_REJEITAR_SUCCESS = 'FATURA_REJEITAR_SUCCESS'
export const FATURA_REJEITAR_FAILED = 'FATURA_REJEITAR_FAILED'

export const FATURA_LIST_CLIENTE = 'FATURA_LIST_CLIENTE'
export const FATURA_LIST_CLIENTE_SUCCESS = 'FATURA_LIST_CLIENTE_SUCCESS'
export const FATURA_LIST_CLIENTE_FAILED = 'FATURA_LIST_CLIENTE_FAILED'


export const FATURAMENTO_CALCULAR_FIXO = 'FATURAMENTO_CALCULAR_FIXO'
export const FATURAMENTO_CALCULAR_FIXO_SUCCESS = 'FATURAMENTO_CALCULAR_FIXO_SUCCESS'
export const FATURAMENTO_CALCULAR_FIXO_FAILED = 'FATURAMENTO_CALCULAR_FIXO_FAILED'

export const AGRUPAMENTO_LIST         = 'AGRUPAMENTO_LIST';
export const AGRUPAMENTO_LIST_SUCCESS = 'AGRUPAMENTO_LIST_SUCCESS';
export const AGRUPAMENTO_LIST_FAILED  = 'AGRUPAMENTO_LIST_FAILED';

export const SHOW_DETALHE_MODAL = 'SHOW_DETALHE_MODAL';

export const CORRIDAS_FATURADAS_LIST            = 'CORRIDAS_FATURADAS_LIST';
export const CORRIDAS_FATURADAS_LIST_SUCCESS    = 'CORRIDAS_FATURADAS_LIST_SUCCESS';
export const CORRIDAS_FATURADAS_LIST_FAILED     = 'CORRIDAS_FATURADAS_LIST_FAILED';

export const DESPESAS_FATURADAS_LIST            = 'DESPESAS_FATURADAS_LIST';
export const DESPESAS_FATURADAS_LIST_SUCCESS    = 'DESPESAS_FATURADAS_LIST_SUCCESS';
export const DESPESAS_FATURADAS_LIST_FAILED     = 'DESPESAS_FATURADAS_LIST_FAILED';

export const AGRUPAMENTO_REMOVE         = 'AGRUPAMENTO_REMOVE';
export const AGRUPAMENTO_REMOVE_SUCCESS = 'AGRUPAMENTO_REMOVE_SUCCESS';
export const AGRUPAMENTO_REMOVE_FAILED  = 'AGRUPAMENTO_REMOVE_FAILED';


export const AGRUPAMENTO_LOAD = 'AGRUPAMENTO_LOAD'
export const AGRUPAMENTO_LOAD_SUCCESS = 'AGRUPAMENTO_LOAD_SUCCESS'
export const AGRUPAMENTO_LOAD_FAILED = 'AGRUPAMENTO_LOAD_FAILED'

export const RECALCULAR = 'RECALCULAR'
export const RECALCULAR_SUCCESS =  'RECALCULAR_SUCCESS'
export const RECALCULAR_FAILED = 'RECALCULAR_FAILED'

export const DASH_FATURAMENTO = 'DASH_FATURAMENTO'
export const DASH_FATURAMENTO_SUCCESS = 'DASH_FATURAMENTO_SUCCESS'
export const DASH_FATURAMENTO_FAILED = 'DASH_FATURAMENTO_FAILED'